import React from 'react';
import josh from '../../assets/josh.jpg';
import deniz from '../../assets/deniz.jpg';
import daniel from '../../assets/daniel.jpg';

import { AiFillLinkedin } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import styles from './services.module.css';

function Services() {
  return (
    <section className={styles.sectionWhite}>
      
      <div className="gpt3__header-content">
      <h1 className="gradient__text3">Meet Our Team</h1>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">

          </div>
          <div className={`row ${styles.teamRow}`}>
            <div className={`col-sm-6 col-md-4 ${styles.teamColumn}`}>
              <div className={styles.teamItem}>
                <img style={{ width: 250, height: 340}} src={josh} alt="josh" className={styles.teamImg} />
                <h3>Josh Shargo</h3>
                <div className={styles.teamInfo}>
                <p style={{ marginBottom: '1rem' }}>Student at GBS</p>
                  <div/>
                  <p>Josh aspired to develop a website that could foster positive change for others by utilizing his computer science skills to make an impact that matters. </p>
                    <ul className={`flex justify-center mt-6`}>
                      <li className="mx-2">
                      <a href="https://www.linkedin.com/in/joshuashargo/" target="_blank" rel="noopener noreferrer">
                        <AiFillLinkedin className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a href="mailto:joshshargo@gmail.com" target="_blank" rel="noopener noreferrer">
                        <MdEmail className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <div className={`col-sm-6 col-md-4 ${styles.teamColumn}`}>
              <div className={styles.teamItem}>
                <img style={{ width: 250, height: 340 }} src={deniz} alt="deniz" className={styles.teamImg} />
                <h3>Deniz Kaptan</h3>
                <div className={styles.teamInfo}>
                <p style={{ marginBottom: '1rem' }}>Student at GBS</p>
                  <p>Deniz took inspiration from his mother, a special eduaction teacher, to pursue this project and help students with special education needs.</p>
                  <ul className={`flex justify-center mt-6`}>
                      <li className="mx-2">
                      <a href="https://www.linkedin.com/in/deniz-kaptan/" target="_blank" rel="noopener noreferrer">
                        <AiFillLinkedin className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a href="mailto:egedenizkaptan@gmail.com" target="_blank" rel="noopener noreferrer">
                        <MdEmail className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`col-sm-6 col-md-4 ${styles.teamColumn}`}>
              <div className={styles.teamItem}>
                <img style={{ width: 250, height: 340 }} src={daniel} alt="daniel" className={styles.teamImg} />
                <h3>Daniel Dai</h3>
                <div className={styles.teamInfo}>
                <p style={{ marginBottom: '1rem' }}>Student at GBS</p>
                  <p>Daniel, with an immense interest in pursuing the area of computer science, found creating TWL's games with the Unity Editor an enriching opportunity.</p>
                  <ul className={`flex justify-center mt-6`}>
                      <li className="mx-2">
                      <a href="https://www.linkedin.com/in/daniel-dai-gbs/" target="_blank" rel="noopener noreferrer">
                        <AiFillLinkedin className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a href="mailto:danieldai2004@gmail.com" target="_blank" rel="noopener noreferrer">
                        <MdEmail className="ai ai-linkedin" size={40} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;


