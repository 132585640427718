import React from 'react'
import './brand.css';
import { unity, gbs, engineering, pltw} from './imports';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <img style={{ width: 175, height: 75 }}src={unity} alt="unity" />
    </div>
    <div>
      <img style={{ width: 175, height: 75 }} src={gbs} alt ="gbs" />
    </div>
    <div>
      <img style={{ width: 125, height: 125 }} src={engineering} alt = "engineering" />
    </div>
    <div>
      <img style={{ width: 175, height: 75 }} src={pltw} alt = "pltw" />
    </div>
  </div>
);

export default Brand;