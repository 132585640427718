import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is TWL" text="Together We Learn is a website made for students with special needs. Our goal is to create a platform where learning is both fun and inclusive. We hope to empower all minds to inspire growth.    " />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text2">Unlocking Your Potential, One Step at a Time</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Personalization" text="With our games, students are able to choose their desired difficulty levels. We strive to make our platform cater to the abilities of our users." />
      <Feature title="Skills" text ="Our games take a comprehensive dive into the fundamental skills of every student. With games that target color assortment, quick decison making, and shape recognition, students have a wide variety of skills they can practice. " />
      <Feature title="Accessibilty" text="At TWL, we strive to accomadate any and all learners. This is why our platform is completly free and accessable on all devices. We plan to further expand our game library so we can continue to target a variety of abilties. " />
    </div>
  </div>
);

export default WhatGPT3;