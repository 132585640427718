import React from 'react';
import { Navbar } from '../../components';
import Contacts from './Contacts.tsx';
import { Footer } from '../../containers';
import useScrollToTop from '../../useScrollToTop';

function Contact() {
    useScrollToTop();

    return (
        <div>
            <Navbar/>
            <Contacts/>
            <Footer />
        </div>
    );
}

export default Contact;