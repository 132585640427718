import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import BallClick from './pages/Game/BallClick';
import Playground from './pages/Game/Playground';
import About from './pages/About/About';
import Contact from './pages/Contacts/Contact';

const router = createBrowserRouter([
{
    path:'/',
    element: <App />,
},
{
    path:'ballclick',
    element: <BallClick />,
},
{
    path:'playground',
    element: <Playground />,
},
{
    path:'about',
    element: <About />,
},
{
    path:'contact',
    element: <Contact />,
},
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />    
);