import axios from 'axios';
import React, { FormEvent, useState, ChangeEvent, useRef } from 'react';
import ReCaPTCHA from 'react-google-recaptcha';
import styles from './Contacts.module.css';

type FormState = {
  email: string;
  name: string;
  message: string;
};

type ServiceMessage = {
  class: string;
  text: string;
}

function Contacts() {
  const formId = 'QVVsHNGh';
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6LePy7UlAAAAADyEcrbJF0m6fxY395bJ2zwHKIgk';
  const recaptchaRef = useRef<any>();


  const initialFormState = {
    email: '',
    name: '',
    message: '',
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const[submitting, setSubmitting] = useState<boolean>(false);
  const[message, setMessage] = useState<ServiceMessage>();
  const[recaptchaToken, setRecaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    const formKey = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[formKey] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchToken = (token: string | null) => {
      setRecaptchaToken(token as string);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        class: 'bg-green-500',
        text: 'Thank you for your message!',
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      setMessage({
        class: 'bg-red-500',
        text: 'Please confirm you are not a robot.',
      });
    }
  };

  return (
    <div className="h-full flex justify-center flex-col">
      <div className="w-2/3 m-auto p-7 shadow-lg">
       <h1 className="text-4xl font-bold flex items-center flex-shrink-0">
       <svg 
         xmlns="http://www.w3.org/2000/svg" 
         viewBox="0 0 26 24" 
         fill="currentColor" 
         className="w-10 h-12"
        >
        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
      </svg>

        <span className="whitespace-nowrap">Contact Us</span>
        </h1>
        {message && (
        <div className={`my-4 text-white w-full p-4 ${message.class}`}>
          {message.text}
        </div>
      )}

        <form className="flex flex-col" onSubmit={submitForm}>
          <div className="my-2 flex flex-col">
            <label htmlFor="name">Name</label>
            <input 
            onChange={updateFormControl}
            type="text" 
            className="border-2 p-2" 
            id="name" 
            value={formState.name}
            /> 
          </div>

          <div className="my-2 flex flex-col">
            <label htmlFor="email">Email</label>
            <input 
            onChange={updateFormControl}
            type="text" 
            className="border-2 p-2" 
            id="email" 
            value={formState.email}
            /> 
          </div>

          <div className="my-2 flex flex-col">
            <label htmlFor="message">Message</label>
            <textarea 
            id="message" 
            className="border-2 p-2" 
            onChange={updateFormControl} 
            value={formState.message}
            ></textarea>
          </div>

          <ReCaPTCHA
            className={styles.recaptcha}
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={updateRecaptchToken}
            />


          <button disabled={submitting}className="my-2 border-2 border-sky-800 bg-white text-sky-900 w-full p-2 hover:bg-sky-800 hover:text-white transtition-colors duration-200">
             {submitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contacts;