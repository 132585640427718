import React from 'react';
import { Navbar } from '../../components';
import { Footer } from '../../containers';
import Game1 from'./Game1.tsx';
import useScrollToTop from '../../useScrollToTop';

function Games() {
    useScrollToTop();

    return (
        <div>
            <Navbar/>
            <Game1/>
            <Footer />
        </div>
    );
}

export default Games;