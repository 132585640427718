import React from 'react';



function Game1 () {
  return (
    <div>
          <iframe 
                src="https://jshargo.github.io/Ball-Click-Game/" 
                width="1000" 
                height="800"
                title="Ball Click Game">
          </iframe>
    </div>
  );
}

export default Game1;



