import React from 'react';
import theboys from '../../assets/theboys.jpg';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
    <img style={{ borderRadius: '10px' }} src={theboys} alt="theboys" />
    </div>
    <div className="gpt3__possibility-content">
    
      <h1 className="gradient__text">How this all came together </h1>
      <p>This project began with a simple goal: to make a difference in the lives of students who need a little extra support. From the very beginning, our team was united in our mission, and we were determined to bring a learning tool to life that would help anyone who interacted with it. With Together We Learn, we feel that we have come one step closer to acheiving that goal in our lifelong mission to help the world. </p>
      <h4>Special thanks to Mr. Wolder, Mrs. Kaptan, Mr. Macfadden & Mr. Sinde</h4>
    </div>
  </div>
);

export default Possibility;