import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Why we started',
    text: 'The lack of personalized learning for those with learning disabilities is a prevalent problem in our school’s community. We started this website to provide a place for these learners to explore their creative side and improve their comprehension skills.',
  },
  {
    title: 'How we built this',
    text: 'We have talked to numerous experts on our journey, ranging from special education teachers to technology entrepreneurs. Their advice and guidance have shaped the basis of our learning platform.',
  },
  {
    title: 'Current endeavors',
    text: 'This website serves as outlet to free and exciting learning games. With this philosophy in mind, we plan to create and deploy more learning opportunites so that students have new skills they can practice.',
  },
  {
    title: 'Our future',
    text: 'We have a vision for our website to evolve into a collaborative platform where any community members can create their own interactive learning games. We hope to become a platform where anyone can help those with learning disabilites.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">We wanted to create a platform that any student could use. Here&apos;s how we are making it happen.</h1>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;