import React from 'react';
import { Navbar } from '../../components';
import Services from './Services';
import { Footer } from '../../containers';
import { Possibility } from'../../containers';
import useScrollToTop from '../../useScrollToTop';


function About() {
    useScrollToTop();

    return (
        <div>
            <Navbar/>
            <Possibility/>
            <Services/>
            <Footer />
        </div>
    );
}

export default About;