import React from 'react';



function Game2 () {
  return (
    <div>
          <iframe 
                src="https://github.com/jshargo/TogetherWeLearn-Playground" 
                width="1000" 
                height="800"
                title="Together We Learn Playground">
          </iframe>
    </div>
  );
}

export default Game2;
