import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png';
import './navbar.css';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
  <img style={{ width: 125, height: 125 }} src={logo} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
        </div>
      </div>
      <div className="gpt3__navbar-sign">
      <p><Link to="/">Home</Link></p>
      <p><Link to="/ballclick">Ball Click</Link></p>
      <p><Link to="/playground">Playground</Link></p>
      <p><Link to="/about">Our Team</Link></p>
      <button type="button"><Link to="/contact">Contact</Link></button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#000" size={35} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#000" size={35} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
          </div>
          <div className="gpt3__navbar-menu_container-links-sign">
          <p><Link to="/">Home</Link></p>
          <p><Link to="/ballclick">Ball Click</Link></p>
          <p><Link to="/playground">Playground</Link></p>
          <p><Link to="/about">Our Team</Link></p>
            <button type="button"><Link to="/contact">Contact</Link></button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;