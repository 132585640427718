import React from 'react';
import kidsplaying from '../../assets/kidsplaying.jpg';
import './header.css';
import { Link } from 'react-router-dom';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Let&apos;s Learn Something</h1>
      <p>Together We Learn is a platform that any student can use. With our intuitive games, we help students by teaching fundamental skills and lessons. With TWL, we unlock student potential, one step at a time. </p>

      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button"><Link to="/contact">Let's Chat</Link></button>
      </div>

      <div className="gpt3__header-content__people">
        <p>  </p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={kidsplaying} alt="kid"/>
    </div>
  </div>
);

export default Header;