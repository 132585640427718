import React from 'react';
import logo from '../../assets/logo.png';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div>
    <div style={{ marginTop: '115px' }}>
      <div className="gpt3__footer section__padding">
        <div className="gpt3__footer-heading">
          <h1 className="gradient__text2">Uniting For a Brighter Tommorrow</h1>
        </div>

        <div className="gpt3__footer-btn">
          <p>
            <Link to="/ballclick">Start Learning Now</Link>
          </p>
        </div>

        <div className="gpt3__footer-links">
          <div className="gpt3__footer-links_logo">
            <img style={{ width: 125, height: 125 }} src={logo} alt="logo" />
            <p>Together We Learn, <br /> All Rights Reserved</p>
          </div>
          <div className="gpt3__footer-links_div">
            <h4>Links</h4>
            <p><a href="#home">Home</a></p>
            <p><Link to="/ballclick">Ball Click Game</Link></p>
            <p><Link to="/playground">TWL Playground</Link></p>
            <p><Link to="/about">About Us</Link></p>
            
          </div>
          <div className="gpt3__footer-links_div">
            <h4>Company</h4>
            <p>Terms & Conditions </p>
            <p>Privacy Policy</p>
            <p><Link to="/contact">Contact</Link></p>
          </div>
          <div className="gpt3__footer-links_div">
            <h4>Get in touch</h4>
            <p>4000 W Lake Ave, Glenview IL</p>
            <p>847-728-2000</p>
            <p>236466@glenbrook225.org</p>
          </div>
        </div>

        <div className="gpt3__footer-copyright">
          <p>@2023 Together We Learn. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
